<template lang="pug">
 div.container
  div(v-if="!organizers || !event")
    loading-spinner(message="Loading event & organizer data...")
  div(v-if="organizers && event")
    div.row.justify-content-center.mb-4
      h1 {{event.organizer}} {{event.name}}
        span(v-if="event.sponsor") with {{event.sponsor}}
        span(v-if="event.location")  @ {{event.location}}

    div.row
      div.col-md-4.d-none.d-md-block
        b-list-group.mb-3
          b-list-group-item(active) Event Info
          b-list-group-item.d-flex.justify-content-between.align-items-center Name <span>{{event.name}}</span>
          b-list-group-item.d-flex.justify-content-between.align-items-center Date <span>{{event.date | moment("MMMM YYYY")}}</span>
          b-list-group-item.d-flex.justify-content-between.align-items-center Organizer <span>{{event.organizer}}</span>
          b-list-group-item.d-flex.justify-content-between.align-items-center(v-if="event.location") Venue <span>{{event.location}}</span>
          b-list-group-item.d-flex.justify-content-between.align-items-center(v-if="event.sponsor") Sponsor <span>{{event.sponsor}}</span>
        b-list-group.mb-3
          b-list-group-item(active) Visitors
          b-list-group-item.d-flex.justify-content-between.align-items-center Total Datapoints <span>{{event.visitors.length}}</span>
          b-list-group-item.d-flex.justify-content-between.align-items-center Displayed Visitor Count <span>{{event.visitorCountOverride == 0 ? event.visitors.length + parseInt(event.visitorCountOffset) : event.visitorCountOverride}}</span>
        b-list-group.mb-3
          b-list-group-item(active) Remove Event From Database
          b-list-group-item.text-center
            p(v-if="globalState.userRole >= 4") Warning: can not be undone!
            p(v-else) Dangerous - for admins only
            b-button(variant="danger" @click="deleteEvent(event)" :disabled="globalState.userRole < 4") Delete Event
      div.col-md-8
        h3 Edit Event Details
        b-form
          b-form-row
            b-form-group.col-sm(label="Event Name *" label-for="name")
              b-input(type="text" name="name" v-model="event.name" required)
            b-form-group.col-sm(label="Date *" label-for="date")
              datepicker(name="date" v-model="event.date" required :bootstrap-styling="true")
            b-form-group.col-sm(label="Organizer *" laber-for="organizer")
              b-select(name="organizer" v-model="event.organizer" :options="organizers" required)
          b-form-row
            b-form-group.col-sm(label="Venue *" label-for="venue")
              b-input(type="text" name="venue" v-model="event.location" required)
            b-form-group.col-sm(label="Sponsor" label-for="sponsor")
              b-input(type="text" name="sponsor" v-model="event.sponsor")
          b-form-row
            b-form-group.col-sm(label="Notes" label-for="notes")
              b-form-textarea(name="notes" v-model="event.notes" :rows="3" :max-rows="6" placeholder="Things that may affect turnout? Weather? Competing events?")
          b-form-row
            b-form-group.col-sm(label="Visitor Count Override *" label-for="visitorCount")
              b-input(type="number" name="visitorCount" v-model="event.visitorCountOverride")
            b-form-group.col-sm(label="Visitor Count Offset *" label-for="visitorOffset")
              b-input(type="number" name="visitorOffset" v-model="event.visitorCountOffset")
          b-form-row.m-4.justify-content-center
            b-button(variant="success" size="lg" @click="updateEvent(event)" :disabled="!isFormValid") Update Event
        hr/
        h3 Event Visitor Flow
        line-chart(v-if="chartData" :data="chartData")
        //- dev-data-dump(:data="chartData")
        hr/
        div
          b-button(@click="getCsv(event)" v-if="!visitorsCSV") Load Event Visitors CSV
          textarea.form-control(v-else rows=5) {{visitorsCSV}}
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import globalState from '@/main.js'
import LineChart from '@/components/LineChart'

export default {
  components: {
    Datepicker,
    'line-chart': LineChart
  },
  data: function () {
    return {
      event: null,
      organizers: null,
      visitorsCSV: null,
      globalState,
      chartData: null
    }
  },
  computed: {
    isFormValid: function () {
      let result = true
      if (this.event.name === '' ||
        this.event.organizer === '' ||
        this.event.location === '' ||
        parseInt(this.event.visitorCountOverride) < 0 ||
        parseInt(this.event.visitorCountOffset) < 0 ||
        this.event.visitorCountOverride === '' ||
        this.event.visitorCountOffset === '') result = false
      return result
    }
  },
  methods: {
    updateEvent: function (event) {
      this.axios.put('/api/events/' + this.event._id, event)
        .then(res => {
          this.$notify({
            group: 'success',
            type: 'success',
            title: 'Event updated',
            text: this.event.name + ' is now up-to-date!'
          })

          this.$router.push({ path: '/events/manage' })
        })
        .catch(err => {
          this.$notify({
            group: 'error',
            type: 'error',
            title: 'Updating event failed',
            text: err.response.data,
            duration: -1
          })
        })
    },
    deleteEvent: function (event) {
      this.axios.delete('/api/events/' + this.event._id, event)
        .then(res => {
          this.$notify({
            group: 'success',
            type: 'success',
            title: 'Event deleted'
          })

          this.$router.push({ path: '/events/manage' })
        })
        .catch(err => {
          this.$notify({
            group: 'error',
            type: 'error',
            title: 'Deleting event failed',
            text: err.response.data,
            duration: -1
          })
        })
    },
    getCsv: function () {
      this.axios.get('/api/events/' + this.event._id + '/visitors')
        .then(res => {
          this.visitorsCSV = '"First Name","Last Name","Email","Organization"'
          for (let i = 0; i < res.data.length; i++) {
            this.visitorsCSV += '\n"' + res.data[i].info.firstName + '","' + res.data[i].info.lastName + '","' + res.data[i].info.email + '","' + res.data[i].info.organization + '"'
          }
        })
        .catch(err => {
          this.$notify({
            group: 'error',
            type: 'error',
            title: 'Fetching event visitors failed',
            text: err.response.data,
            duration: -1
          })
        })
    }
  },
  mounted: function () {
    this.axios.get('/api/events/organizers')
      .then(res => {
        this.organizers = [{
          value: null, text: 'Select an organizer'
        }]
        for (let o of res.data) {
          this.organizers.push({
            value: o.name, text: o.name
          })
        }
      })
      .catch(err => {
        this.$notify({
          group: 'error',
          type: 'error',
          title: 'Fetching organizers failed',
          text: err.response.data,
          duration: -1
        })
      })

    this.axios.get('/api/events/' + this.$route.params.id)
      .then(res => {
        this.event = res.data

        // Round to closest 15 min
        let checkins = []
        for (let i = 0; i < res.data.visitors.length; i++) {
          let date = new Date(res.data.visitors[i].checkin)
          date.setHours(((((date.getMinutes() / 105) + 0.5) | 0) + date.getHours()) % 24, (((date.getMinutes() + 7.5) / 15 | 0) * 15) % 60, 0, 0)
          checkins.push(date.valueOf())
        }

        // Count dupes
        let x = []; let y = []; let prev
        checkins.sort()

        for (let i = 0; i < checkins.length; i++) {
          if (checkins[i] !== prev) {
            x.push(checkins[i])
            y.push(1)
          } else {
            y[y.length - 1]++
          }
          prev = checkins[i]
        }

        // Generate actual chart array from dupe count
        let finalData = []
        for (let i = 0; i < x.length; i++) {
          finalData.push({ x: x[i], y: y[i] })
        }

        // Push to reactive DOM
        // this.chartData = finalData
        this.chartData = {
          // labels: ['January', 'February'],
          datasets: [
            {
              label: 'Event visits',
              // backgroundColor: '#f87979',
              data: finalData
            }
          ]
        }
      })
      .catch(err => {
        this.$notify({
          group: 'error',
          type: 'error',
          title: 'Fetching event data failed',
          text: err.hasOwnProperty('response') ? err.response.data : err,
          duration: -1
        })
      })
  }
}
</script>
